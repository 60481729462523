import * as React from 'react';
import { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import { bdAxios } from '../components/utils/Axios';
import { parseJwt, getToken } from '../components/utils/Common';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from 'react-select'
import Loading from '../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import PopUpDeleteConfirmation from '../components/PopUpDeleteConfirmation'

function OffresEmplois() {

	const [tab, setTab] = useState<string>('list');
	const [pdf, setPDF] = useState<Array<any>>([]);
	const [sliderPictures, setSliderPictures] = useState<Array<any>>([]);
	const [deletedPictures, setDeletedPictures] = useState<Array<any>>([]);
	const [jobs, setJobs] = useState<Array<any>>([]);
	const [allJobs, setAllJobs] = useState<Array<any>>([]);
	const [files, setFiles] = useState<Array<any>>([]);
	const [offices, setOffices] = useState<Array<any>>([]);
	const [jobContent, setJobContent] = useState<any>();
	const [selectedJob, setSelectedJob] = useState<any>();
	const [message, setMessage] = useState<string>("");
	const [error, setError] = useState<string>("");
	const [selectedOffice, setSelectedOffice] = useState<any>(0);
	const [v, setV] = useState<number>(0);
	const [officeId, setOfficeId] = useState<number>(0);
	const [description, setDescription] = useState<string>("");
	const [PDFDocument, setPDFDocument] = useState<any>(null);
	const [showPDFSaveText, setShowPDFSaveText] = useState<any>(false);
	const [newSliderPictures, setNewSliderPictures] = useState<FileList | null>();
	const [showSliderPictureText, setShowSliderPictureText] = useState<any>(false);
	const [popUpVisibility, setPopUpVisibility] = useState<boolean>(false);
	const [offerToDelete, setOfferToDelete] = useState<number | null>(null);

	useEffect(() => {
		const dJobs: any = [];
		if (!selectedOffice) {
			setJobs(allJobs);
			return;
		}

		allJobs.map((job: any) => {
			if (job?.fields?.office?.ID === selectedOffice) {
				dJobs.push(job);
			}
		});

		setJobs(dJobs);
	}, [selectedOffice]);

	const getOfficeOptions = () => {
		if (!offices) {
			return [{label: "Sélectionner une étude", value:null}];
		}
		const options: Array<any> = [{label: "Sélectionner une étude", value:null}];
		offices.sort((a:any , b: any) => {
			return a.post_title.localeCompare(b.post_title);
		});
		offices.map((office) => {
			if (office.post_parent != '0') {
				return;
			}
			options.push({value: office.ID, label: office.post_title})
		});

		return options;
	}

	const getSelectedOffice = () => {
		if (!selectedJob || !selectedJob.hasOwnProperty('fields')) {
			return [];
		}

		return [{value: selectedJob.fields.office.ID, label: selectedJob.fields.office.post_title}];
	}

	useEffect(() => {
		let token = (getToken() ? getToken() : '');
		if (token === null) {
			return;
		}

		if (selectedJob && selectedJob.hasOwnProperty('fields')) {
			setSliderPictures(selectedJob.fields.slider);
		}
		const user = parseJwt(token);
		if (user.data.roles && Array.isArray(user.data.roles)) {

		} else {

		}
		bdAxios.get('myoffices', {headers:{
			'Authorization': "Bearer " + token
		}}).then((r) => {
			setOffices(r.data.offices);
		})

	}, [selectedJob]);

	const loadJobs = (token:string) => {
		bdAxios.get('myjobs', {headers:{
			'Authorization': "Bearer " + token
		}}).then((r) => {
			setJobs(r.data.jobs);
			setAllJobs(r.data.jobs);
		})
	}

	useEffect(() => {
		setTab('list');

		let token = (getToken() ? getToken() : '');
		if (token === null) {
			return;
		}
		const user = parseJwt(token);
		if (user.data.roles && Array.isArray(user.data.roles)) {

		} else {

		}
		bdAxios.get('myjobs', {headers:{
			'Authorization': "Bearer " + token
		}}).then((r) => {
			setJobs(r.data.jobs);
			setAllJobs(r.data.jobs);
		})
	}, [])

	const submit = () => {
		let token = (getToken() ? getToken() : '');
		if (token === null) {
			return;
		}

		setMessage('')
		setError('')

		function scrollToTop() {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		}		
		const titleInput: any = document.querySelector("input[name='title']");
		
		if (!titleInput.value){
			setError("Le champ 'Titre' ne peut pas être vide");
			scrollToTop()
			return;
		}
		
		const pdfInput: any = document.querySelector("input[name='pdf']");

		if (!description && !pdfInput.value && !PDFDocument){
			setError("Veuillez ajouter un fichier PDF ou une description.");
			scrollToTop()
			return;
		}
 
		if (!officeId){
			setError("Le champ 'Étude' ne peut pas être vide");
			scrollToTop()
			return;
		}

		const form: any = document.querySelector("#offer-form")
		const formData = new FormData(form);
		formData.append('content', jobContent);


		if (selectedJob) {
			// update
			formData.append('id', selectedJob.ID);
			formData.append('deleted_pictures', JSON.stringify(deletedPictures));
			bdAxios.post('update/offer', formData, {
				headers: {
					'authorization': 'Bearer ' + token,
					'Content-Type': 'multipart/form-data'
				}
			}).then((r) => {
				scrollToTop();
				setMessage( r.data.message );
			})
		} else {
			bdAxios.post('create/offer', formData, {
				headers: {
					'authorization': 'Bearer ' +  token,
					'Content-Type': 'multipart/form-data'
				}
			}).then((r) => {
				reset();
			})
		}
	}

	const handleOfficeChange = (e: any) => {
		setOfficeId(e.value);
	}

	const handlePDFUpdate = (e: any) => {
		setPDF(e.target.files);
	}

	const handleFileUpdate = (e: any) => {
		setFiles(e.target.files);
	}

	const deletePicture = (id: any) => {
		setDeletedPictures( [...deletedPictures, id]);

		const p = sliderPictures.filter((picture: any) => { return picture.image.ID !== id});
		setSliderPictures(p);
	}

	const reset = () => {
		setDeletedPictures([]);
		setSliderPictures([]);
		setSelectedJob(null);
		setMessage('');
		setError('');
		setTab('list');
		let token = (getToken() ? getToken() : '');
		if (token === null) {
			return;
		}
		loadJobs(token);
	}

	const deleteOffer = (id: any) => {

		let token = (getToken() ? getToken() : '');
		if (token === null) {
			return;
		}
		const user = parseJwt(token);
		if (user.data.roles && Array.isArray(user.data.roles)) {

		} else {

		}
		bdAxios.post('delete/offer',{offer_id: id}, 
		{headers: {
			'Authorization': "Bearer " + token
			}
		}).then((r) => {
			reset();
		})
	}

	const getValue = (key: string, object: any) => {
		if (object && object.hasOwnProperty(key)) {
			return object[key];
		}

		return '';
	}

	const selectJob = (job: any) => {
		setMessage('');
		setError('');
		setSelectedJob(job);
		setTab('job');
		setJobContent(job?.post_content);
		setOfficeId(job.fields.office.ID);
		if (job?.fields?.pdf?.url) {
			setPDFDocument(job?.fields?.pdf);
		}
	}

	const filterOffices = (choice: any) => {
		if (choice.value === null) {
			setJobs(allJobs);
			return;
		}

		const lJobs: any = [...allJobs];
		const js: any = [];
		lJobs.map((job: any) => {
			if (job.fields.office.ID === choice.value || job.fields.office.post_parent == choice.value) {
				js.push(job)
			}
		});

		setJobs(js);
	}


	const selectOffice = (evt: any) => {
		setSelectedOffice(evt.value);
		
		// setEmployees(dEmployees);
		// setV(v => v+1);
	}

	const getFileName = (file: any) => {

		if(file){

			// ID is the wordpress file property
			if (file.ID){

				// the wordpress file uses 3 properties to create a descriptive file name
				const title = file.title? file.title : ''
				const type = file.subtype? file.subtype : ''

				return `${title}.${type}`

			} else if ( file.name ) {

				// the new file will only use the name
				const name = file.name
				return `${name}`

			} else {
				const name = file.url.split('/').pop()
				return `${name}`
			}
		}

		return ''
	}

	const handleSliderPicturesNames = () => {

		if(newSliderPictures){

			const fileArray = [...newSliderPictures]

			const tempFileArray = fileArray.map((picture: File) => {
				return <div key={picture.name + picture.lastModified}><p style={{color:'black', fontWeight: 600}}>{getFileName(picture)}</p></div>
			})
			return tempFileArray
		}
		return null
	}

	// const getOfficeOptions = () => {
	// 	if (!offices) {
	// 		return [{label:"Sélectionner une étude", value:null}];
	// 	}

	// 	const options: Array<any> = [{label:"Sélectionner", value:null}];
	// 	const tree = new Map();

	// 	offices.sort((a:any , b: any) => {
	// 		return a.post_title.localeCompare(b.post_title);
	// 	});
		
	// 	offices.map((office: any) => {
	// 		if (office.post_parent == '0' || !office.post_parent) {
	// 			tree.set(office.ID, {parent: office, children:[]});
	// 		}
	// 	});

	// 	offices.map((office: any) => {
	// 		if (office.post_parent != '0' || !!office.post_parent) {
	// 			const v = tree.get(office.post_parent);
	// 			v.children.push(office);
	// 			tree.set(office.post_parent, v);
	// 		}
	// 	})

	// 	tree.forEach((group: any) => {

	// 		let g:any = {
	// 			label: group.parent.post_title,
	// 			options: []
	// 		};
	// 		group.children.map((child:any) => {
	// 			g.options.push({label: "  "+child.post_title, value: child.ID});
	// 		});

	// 		options.push(g);
	// 	})

	// 	return options;
	// }

	return (
		<Layout title="Accueil" classes="accueil-page">

			<PopUpDeleteConfirmation popUpVisibility={popUpVisibility} setPopUpVisibility={setPopUpVisibility} deleteFunction={ () => deleteOffer(offerToDelete) }/>

			<div className="inner-page">
				<h1 className="title">Offres d'emplois</h1>
				{ error ?
					<div className="notice p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
					  <span className="font-medium">{error}</span>
					</div>
					: void(0)
				}
				{ message ?
					<div className="notice p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
					  <span className="font-medium">{message}</span>
					</div>
					: void(0)
				}
				
				<div className="content">
					{ !selectedJob ? 
						<>
						{
							tab === 'job' ?
								<div className="action-row">
									<div onClick={() => reset()} className="button">Retour</div>
								</div>
							:void(0) 
						}
						</>
						: void(0)
					}
					{ tab === 'list' ?
						<>
							<form>
								<fieldset>
									<Select
										options={getOfficeOptions()}
										defaultValue={{label:"Sélectionner une étude", value:null}}
										onChange={selectOffice}
									/>
									<br />
									<div className="action-row">
										<div onClick={() => {setTab('job')}} className="button create-button">+ Ajouter une offre</div>
									</div>
								</fieldset>
							</form>
							<table className="employees-table">
								<thead>
									<tr>
										<td>Nom</td>
										<td>Étude</td>
										<td>Afficher/Modifier</td>
										<td>Supprimer</td>
									</tr>
								</thead>
								<tbody>
								{
									(jobs && jobs.length > 0) ?
									jobs.map((job) => {
										return (
											<tr key={job.ID}>
												<td>{job?.post_title}</td>
												<td>{job?.fields?.office?.post_title}</td>
												<td><div className="button" onClick={(e) => {selectJob(job) }}>Voir</div></td>
												<td><div onClick={ () => { setPopUpVisibility(true); setOfferToDelete(job.ID) }} className="button delete">Supprimer</div></td>
											</tr>
										);
									})
									: void(0)
								}
								</tbody>
							</table>
						</>
						: tab !== 'job' ? <Loading /> : void(0)
					}
					{ tab === "job" ?
						<>
							
						    <form id="offer-form">
						    <fieldset>
						         <label>Titre *</label>
						         <br/>
								 <input type="text" name="title" defaultValue={getValue('post_title', selectedJob)} />
								 
								 <label>Téléverser un PDF:</label>
									
									<div style={{display:'flex', columnGap: '2%'}}>
											<label className="button" htmlFor="pdf" style={{display: 'flex', width: "unset", margin: 0}}>
												{
													PDFDocument ? 
													<FontAwesomeIcon icon={faPencil} /> : 
													'Téléverser un document '
												}
												<input type="file" name="pdf" id="pdf" style={{display:'none'}} 
													onChange={
														e=>{
															setPDFDocument(e.target.files?.length ? e.target.files[0] : null)
															setShowPDFSaveText(true)
														}
													}
												/>
											</label>
											{ PDFDocument ?
											<div style={{display: 'flex', alignItems: 'center'}}>
												<div>
												<a href={PDFDocument?.url} style={{textDecoration:'underline'}} target="_blank">{getFileName(PDFDocument)}</a>
													{
														showPDFSaveText ? <p style={{color:'red'}} >N'oubliez pas de sauvegarder le formulaire pour terminer le transfert du nouveau document. </p>: void(0)
													}
												</div>
											</div>
											: void(0) }
									</div>

			                	<div className="formRow">
			                		<label>Étude *</label>
			                		<Select
			                			options={getOfficeOptions()}
			                			defaultValue={getSelectedOffice()}
			                			name="office"
										onChange={(e)=>handleOfficeChange(e)}
			                		/>
			                	</div>

				                <label>Description</label>
								<CKEditor
				                    editor={ ClassicEditor }
				                    data={getValue('post_content', selectedJob)}
				                    onReady={ (editor: any) => {
										setDescription(editor.getData());
				                    } }
				                    onChange={ ( event: any, editor: any ) => {
										const data = editor.getData();
										setDescription(data);
				                    } }
				                    onBlur={ ( event: any, editor: any ) => {
				                        setJobContent(editor.getData());
				                    } }
				                    onFocus={ ( event: any, editor: any ) => {
				                    } }
				                />

				                <label>Galerie photo</label>
				                <br/>
								<div style={{display:'flex', columnGap: '2%'}}>
								<label className="button" htmlFor="sliderPictures" style={{display: 'flex', width: "unset", margin: 0}}>
										{
											newSliderPictures ? 
											<FontAwesomeIcon icon={faPencil} /> : 
											'Téléverser une image'
										}
										<input type="file" name="sliderPictures" id="sliderPictures" multiple={true} style={{display:'none'}} onChange={
											e=>{
												handleFileUpdate(e)
												setShowSliderPictureText(true)
												setNewSliderPictures(e.target.files)
											}
										}/>
									</label>
									<div style={{display: 'flex', alignItems: 'center'}}>
										<div>

											{
												showSliderPictureText ? 
												<p style={{color:'red'}} >N'oubliez pas de sauvegarder le formulaire pour terminer le transfert de la nouvelle image.</p>
												: void(0)
											}
										</div>
									</div>

								</div>
								{
									handleSliderPicturesNames()
								}
								<br/>
				                <div className="pictures-wrapper">
					                {
					                	(sliderPictures && sliderPictures.length > 0) ?
					                	sliderPictures.map((file:any) => {
					                		return (
					                			
						                			<div key={file.image.ID}>
						                				<img className="picture" src={file.image.url}/>
						                				<span onClick={() => deletePicture(file.image.ID)} className="delete-icon">X</span>
					                				</div>
					                			
					                		)
					                	})
					                	: void(0)
					                }
					            </div>
								<br/>
				                <div onClick={submit} className="button">Sauvegarder</div>
				                </fieldset>
							</form>
						</>

					: void(0)
					}
				</div>
			</div>
		</Layout>
	)
}

export default OffresEmplois;